export default ({ color = "#fff", className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="32"
    width="32"
    viewBox="0 0 32 32"
    className={className}
    style={style}
  >
    <g>
      <circle clipRule="evenodd" cx="16" cy="16" fill="#007BB5" fillRule="evenodd" r="16" />
      <g>
        <rect fill="#FFFFFF" height="14" width="4" x="7" y="11" />
        <path
          d="M20.499,11c-2.791,0-3.271,1.018-3.499,2v-2h-4v14h4v-8c0-1.297,0.703-2,2-2c1.266,0,2,0.688,2,2v8h4v-7    C25,14,24.479,11,20.499,11z"
          fill="#FFFFFF"
        />
        <circle cx="9" cy="8" fill="#FFFFFF" r="2" />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <circle cx="16" cy="16" fill="#007BB5" fillRule="evenodd" r="16" />
  </svg>
);
